import React from "react";
import styles from "./index.module.css";

type Props = {
	children: React.ReactNode;
};

const Body = (props: Props) => {
	const { children } = props;

	return (
		<div id="body" className={styles.body}>
			{children}
		</div>
	);
};

export default Body;
