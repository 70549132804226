import React from "react";
import HashLoader from "react-spinners/HashLoader";
import styles from "./index.module.css";

type Props = {
	type: "page" | "component";
};

const Loading = (prop: Props) => {
	const { type } = prop;

	const color = "#007b8a";
	return (
		<>
			{type === "component" && (
				<div className={styles.wrapper}>
					<HashLoader size={70} color={color} />
				</div>
			)}
			{type === "page" && (
				<div className={styles.wrapperPage}>
					<HashLoader size={70} color={color} />
				</div>
			)}
		</>
	);
};

export default Loading;
