import config from "../config";
import axios from "axios";
import UserService, { UserType } from "../services/UserService";

class Auth {
  constructor() {
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.getToken = this.getToken.bind(this);
    this.getProfile = this.getProfile.bind(this);
  }

  getUserProfile() {
    var rawProfile = localStorage.getItem("userprofile");
    if (rawProfile != null) {
      return JSON.parse(rawProfile);
    }
    return {};
  }

  async userLogin() {
    let user: any = this.getUserProfile();

    if (user === null || user.oid === undefined) {
      var response = await axios.get(`${config.server_host}/user`, {
        withCredentials: true,
      });
      if (typeof response.data.oid === "undefined") {
        window.location.href = `${config.server_host}/login-b2b`;
        return false;
      } else {
        this.setUserProfile(response.data);
        return true;
      }
    } else {
      return true;
    }
  }

  async getLogedUserByDruid(): Promise<UserType | undefined> {
    let druidId = this.getDruidId();
    if (druidId) {
      let user: UserType | undefined = await UserService.getUserByDruid(
        druidId
      ).catch(() => {
        return undefined;
      });
      return user;
    } else {
      return undefined;
    }
  }

  setUserProfile(authResponse: any) {
    var userProfile = this.getUserProfile();
    //credentials, token y refresj token
    var fullName: string[] = [];
    var initials: string = "";
    if (
      authResponse.mainUserIdentity !== undefined &&
      authResponse.mainUserIdentity != null
    ) {
      const fields = ["firstName", "lastName", "secondLastName"];

      for (var fieldIndex in fields) {
        var field = fields[fieldIndex];

        if (
          authResponse.mainUserIdentity[field] !== undefined &&
          authResponse.mainUserIdentity[field] !== null
        ) {
          fullName.push(`${authResponse.mainUserIdentity[field]}`);
          var fullNameWords = authResponse.mainUserIdentity[field].split(" ");
          if (fullNameWords.length > 0) {
            for (var wordIndex in fullNameWords) {
              if (initials.length < 3 && fullNameWords[wordIndex].length > 0) {
                initials += fullNameWords[wordIndex][0].toUpperCase();
              }
            }
          } else {
            initials = "--";
          }
        }
      }
    } else {
      if (fullName.length === 0) {
        fullName = [userProfile.email];
        initials = "@";
      }
    }

    authResponse.fullName = fullName.join(" ");
    authResponse.initials = initials;

    localStorage.setItem(
      "userprofile",
      JSON.stringify({
        ...userProfile,
        ...Object.fromEntries(
          Object.entries(authResponse).filter(([_, v]) => v != null)
        ),
      })
    );
  }

  setSession(authResult: any) {
    localStorage.setItem("email", authResult.email);
    localStorage.setItem("oid", authResult.oid);
  }

  getToken() {
    if (localStorage.getItem("access_token") === null) {
      return "";
    }

    return localStorage.getItem("access_token");
  }

  getDruidId() {
    if (localStorage.getItem("userprofile") === null) {
      return "";
    }

    return JSON.parse(localStorage.getItem("userprofile")!).oid;
  }

  getProfile() {
    let user;
    let apiProfile;

    if (
      localStorage.getItem("email") &&
      localStorage.getItem("oid") !== "undefined"
    ) {
      user = JSON.parse(localStorage.getItem("profile") as string);
    } else {
      user = {};
    }

    const profile = Object.assign(user, apiProfile);
    return profile || {};
  }

  logout() {
    localStorage.clear();
    axios.get(`${config.server_host}/oauth/revoke`, { withCredentials: true }).finally(() => 
    window.location.href = `${config.server_host}/login-b2b`
    );
    
  }

  isAuthenticated() {
    if (
      localStorage.getItem("email") &&
      localStorage.getItem("oid") !== "undefined"
    ) {
      return true;
    } else {
      return false;
    }
  }
}

const authClient = new Auth();

export default authClient;