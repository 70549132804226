var config: any = {};

switch(process.env.NODE_ENV)
{
    case "production":
    {
        config =
        {
            proxy_url: "#{PROXY_URL}#",
            server_host: "#{SERVER_HOST}#",
            funds_service: "#{FUNDS_SERVICE}#",
            powerbi_service_url: "#{POWERBI_SERVICE_URL}#",
            powerbi_api_reporttokens_code: "#{POWERBI_API_REPORTTOKENS_CODE}#"
        };
        break;
    }
    default:
    {
        config =
        {
            proxy_url: process.env.REACT_APP_PROXY_URL,
            server_host: process.env.REACT_APP_SERVER_HOST,
            funds_service: process.env.REACT_APP_FUNDS_SERVICE,
            powerbi_service_url: process.env.REACT_APP_POWERBI_SERVICE_URL,
            powerbi_api_reporttokens_code: process.env.REACT_APP_POWERBI_API_REPORTTOKENS_CODE,
        };
        break;
    }
}

export default config;