import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import AuthenticatedRoute from "./authenticatedRoute";

const Report = lazy(() => import("../views/Report"));
const Presence = lazy(() => import("../views/Presence"));
const NotFound = lazy(() => import("../views/NotFound"));

const CustomRoutes = () => {
	return (
		<Suspense>
			<Routes>
				<Route path="/" element={<AuthenticatedRoute element={<Report />} />} />
				<Route path="/presence" element={<AuthenticatedRoute element={<Presence />} />} />
				<Route path="*" element={<AuthenticatedRoute element={<NotFound />} />} />
			</Routes>
		</Suspense>
	);
};

export default CustomRoutes;
