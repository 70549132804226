import * as React from "react";
import { connect } from "react-redux";
import Auth from "../auth/Auth";
import { Dispatch } from "redux";
import { PartnerFilters } from "../services/UserService";
import { authActions as actions } from "../store/auth";
import Loading from "../components/Loading";

type Props = {
  element: any;
  isAuthenticated: boolean;
  isPartner: boolean;
  presenceId: number | null | undefined;
  userIsAuthenticated: (isAuthenticated: boolean) => void;
  userIsPartner: (isPartner: boolean) => void;
  userReportFilters: (reportFilters: PartnerFilters[]) => void;
  userPresenceId: (presenceId: number | null | undefined) => void;
};

const AuthenticatedRoute = (props: Props) => {
  const {
    element,
    isAuthenticated,
    isPartner,
    presenceId,
    userIsAuthenticated,
    userIsPartner,
    userReportFilters,
    userPresenceId,
  } = props;
  const [isUserLoaded, setIsUserLoaded] = React.useState(false);

  const checkUser = React.useCallback(async () => {
    let userAuthenticated = await Auth.userLogin();
    userIsAuthenticated(userAuthenticated);
    if (!userAuthenticated) {
      Auth.logout();
    }
    let user = await Auth.getLogedUserByDruid();
    userIsPartner(user?.isPartner ?? false);
    userPresenceId(user?.presenceId);
    if (!user || !user?.isPartner) {
      Auth.logout();
    }
    if (user) {
      userReportFilters(user.partnerFilters ?? []);
    }
  }, [userIsAuthenticated, userIsPartner, userReportFilters, userPresenceId]);

  React.useEffect(() => {
    if (!isUserLoaded) {
      checkUser().finally(() => {
        setIsUserLoaded(true);
      });
    }
  }, [checkUser, isUserLoaded]);

  React.useEffect(() => {
    if (isUserLoaded && (isAuthenticated === false || isPartner === false)) {
      Auth.logout();
    }
  }, [isUserLoaded, isAuthenticated, isPartner]);

  if (!isUserLoaded) {
    return <Loading type="page"></Loading>;
  }
  
  if (isUserLoaded && (isAuthenticated === false || isPartner === false))
    return null;

  return element;
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isPartner: state.auth.isPartner,
  presenceId: state.auth.presenceId
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userIsAuthenticated: (isAuthenticated: boolean) =>
    dispatch(actions.userIsAuthenticated(isAuthenticated)),
  userIsPartner: (isPartner: boolean) =>
    dispatch(actions.userIsPartner(isPartner)),
  userReportFilters: (reportFilters: PartnerFilters[]) =>
    dispatch(actions.userReportFilters(reportFilters)),
  userPresenceId: (presenceId: number | null | undefined) =>
    dispatch(actions.userPresenceId(presenceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoute);
