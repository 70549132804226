import { applyMiddleware, combineReducers, compose, legacy_createStore as createStore } from "redux";
import { reducer as authReducer } from "./auth";
import { createLogger } from "redux-logger";


const logger = createLogger({
	collapsed: true,
	level: "log"
});

export default function configureStore() {
	const reducers = {
		auth: authReducer
	};

	// In development, use the browser's Redux dev tools extension if installed
	const enhancers: any = [];
	// const isDevelopment = process.env.NODE_ENV === "development";
	// if (isDevelopment && typeof window !== "undefined" && window.devToolsExtension) {
	// 	enhancers.push(window.devToolsExtension());
	// }

	const rootReducer = combineReducers({
		...reducers
	});

	const initialState: any = {};
	const store = createStore(rootReducer, initialState, compose(applyMiddleware(logger), ...enhancers));
	
	return store;
}
