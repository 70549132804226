import axios from "axios";
import config from "../config";
import { setupInterceptorsTo } from "./Interceptors";
setupInterceptorsTo(axios);
const proxy: string | undefined = config.proxy_url;

interface IUser
{
    id?: number,
    druId?: string,
    finametrixId?: string,
    status?: any,
    email?: string,
    createdAt?: string,
    updatedAt?: string,
	isPartner?: boolean,
	partnerFilters?: PartnerFilters[],
    presenceId?: number | null
}

export type PartnerFilters = {
	id: number,
	userId: number,
	reportId: string,
	table: string,
	column: string,
    filterType: string,
	logicalOperator: string,
    filterDisplayName: string,
	partnerFilterConditions: PartnerFiltersConditions[],
}

export type PartnerFiltersConditions = {
	id: number,
	partnerFilterId: number,
	conditionOperator: string,
	conditionValue: string,
}

export type UserType = IUser;


class UserService
{
    public getUserByDruid = async(userDruId: string): Promise<UserType> =>
    {
        let url: string = `${proxy}/users/getByDruId/${userDruId}`;
        const req = await axios.get<UserType>(url);
        return req.data;
    }

}

const users = new UserService();

export default users;