// Actions
import { PartnerFilters } from "../../services/UserService";
import { authActions as actions } from "./actions";

// Types
import { AnyAction } from "redux";

export type UserStateType = {
  isAuthenticated: boolean;
  isPartners: boolean;
  reportFilters: PartnerFilters[];
  presenceId: number | null | undefined;
};

// Default state
const defaultState: UserStateType = {
  isAuthenticated: false,
  isPartners: false,
  reportFilters: [],
  presenceId: undefined,
};

export const reducer = (state = defaultState, action: AnyAction) => {
  const { payload } = action;

  switch (action.type) {
    case actions.USER_IS_AUTHENTICATED:
      return { ...state, isAuthenticated: payload };
    case actions.USER_IS_PARTNER:
      return { ...state, isPartners: payload };
    case actions.USER_REPORT_FILTERS:
      return { ...state, reportFilters: payload };
    case actions.USER_PRESENCE_ID:
      return { ...state, presenceId: payload };
    default:
      return state;
  }
};
