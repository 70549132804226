import * as React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Auth from "../../../auth/Auth";
import { NavLink } from "react-router-dom";
import styles from "./index.module.css";
import { connect } from "react-redux";


type Props = {
  presenceId: number | null | undefined;
};

const Header = (props: Props) => {
  const {
    presenceId,
  } = props;


  const handleLogOut = () => {
    Auth.logout();
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Brand style={{ fontSize: "25px"}}>
            <NavLink className={styles.navlink} to="/">
              Ironia
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <NavLink to="/" className={styles.navlink}>
                Inicio
              </NavLink>
              {presenceId && <NavLink to="/presence" className={styles.navlink}>
                Presence
              </NavLink>}
            </Nav>
            <Nav
              className="ml-auto justify-content-end"
              style={{ width: "100%" }}
            >
              <NavLink
                className={styles.navlink}
                to="#LogOut"
                onClick={() => {
                  handleLogOut();
                }}
              >
                Cerrar sesión
              </NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  presenceId: state.auth.presenceId
});

export default connect(mapStateToProps, null)(Header);
