// Helpers
import { dispatch } from "../../helpers/actions";
import { PartnerFilters } from "../../services/UserService";

const USER_IS_AUTHENTICATED = "USER_IS_AUTHENTICATED";
const USER_IS_PARTNER = "USER_IS_PARTNER";
const USER_REPORT_FILTERS = "USER_REPORT_FILTERS";
const USER_PRESENCE_ID = "USER_PRESENCE_ID";

export const authActions = {
	USER_IS_AUTHENTICATED,
	USER_IS_PARTNER,
	USER_REPORT_FILTERS,
	USER_PRESENCE_ID,
	userIsAuthenticated: (payload: boolean) => dispatch(USER_IS_AUTHENTICATED, payload),
	userIsPartner: (payload: boolean) => dispatch(USER_IS_PARTNER, payload),
	userReportFilters: (payload: PartnerFilters[]) => dispatch(USER_REPORT_FILTERS, payload),
	userPresenceId: (payload: number | null | undefined) => dispatch(USER_PRESENCE_ID, payload)
};
