import React from "react";
import "./App.css";
import Body from "./components/layout/Body";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import CustomRoutes from "./routes";
import Auth from "./auth/Auth";
import { connect } from "react-redux";
import { Dispatch } from "redux";

// Store
import { authActions as actions } from "./store/auth";
import { PartnerFilters } from "./services/UserService";

type Props = {
  userIsAuthenticated: (isAuthenticated: boolean) => void;
  userIsPartner: (isPartner: boolean) => void;
  userReportFilters: (reportFilters: PartnerFilters[]) => void;
};

function App(props: Props) {

  return (
    <div className="App">
      <Header></Header>
      <Body>
         <CustomRoutes />
      </Body>
      <Footer></Footer>
    </div>
  );
}

const mapStateToProps = (state: any) => ({});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  userIsAuthenticated: (isAuthenticated: boolean) =>
    dispatch(actions.userIsAuthenticated(isAuthenticated)),
  userIsPartner: (isPartner: boolean) =>
    dispatch(actions.userIsPartner(isPartner)),
  userReportFilters: (reportFilters: PartnerFilters[]) =>
    dispatch(actions.userReportFilters(reportFilters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
