import styles from "./index.module.css";

const Footer = () => {
  return (
    <footer id="footer" className={styles.footer}>
      <div className={styles.footerText}>
        <p>© 2023 IronIA · Todos los derechos reservados.</p>
      </div>
    </footer>
  );
};

export default Footer;
